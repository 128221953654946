//import React from 'react'
import ReactGA from 'react-ga'
import ReactGTM from 'react-gtm-module'
import { map } from 'lodash-es'
import { noop } from '../Utils/Utils'

class Analytics
{
	type
	options
	trackers
	events
	
	constructor({ type='ga',id,options={},events={} })
	{
		this.type = type
		this.options = options
		this.events = events
		this.isLoaded = false
		
		if(this.options.disabled)
			return this

		//console.log('Analytics()',arguments)

		this.addListeners()

		switch(type)
		{
			case 'gtm':

				ReactGTM.initialize(
				{
					gtmId: id,
					noAutoRun: true
				})
				
				break

			case 'ga':
			default:

				this.trackers = []
				
				ReactGA.initialize(map(id, v => 
				{
					const isString = typeof v === 'string'
					const isObject = typeof v === 'object' && !Array.isArray(v)
					
					switch(true)
					{
						case isObject:
							let id = v.id
							v.id = null
							delete v.id

							v = { id:id, options: {...v}}
							
							break
							
						case isString:
						default:

							v = { id:v, options:{ ...options.options }}

							break
					}

					const trackerId =  `tracker_${v.id.replace(/[^\w]+/g,'')}`
					this.trackers.push(trackerId)

					return {
						trackingId: v.id,
						gaOptions:
						{
							name: trackerId,
							...v.options
						}
					}
				}), 
				{ 
					debug: options.debug, 
					alwaysSendToDefaultTracker: false 
				})
		}

		return this
				
	}

	addListeners = () =>
	{
		switch(this.type)
		{
			case 'gtm':

				if(this.isLoaded)
				{
					this.onLoad()
				}	
				else
				{
					window.addEventListener('gtm_loaded',this.onLoad)
					window.addEventListener('gtm_error',this.onError)
				}
				
				break

			case 'ga':
			default:
				
				// ish don't think so...
				
		}
	}

	pageView = (url,data={}) =>
	{
		if(this.options.disabled)
			return noop
			
		url = url || window.location.pathname
		data.url = url
				
		console.log('Analytics.pageView()', { ...data })
				
		switch(this.type)
		{
			case 'gtm':
				
				ReactGTM.dataLayer(
				{
					dataLayer: 
					{
						event: 'PageView',
						...data
					}
				})

				break

			case 'ga':
			default:
				
				ReactGA.pageview(url,[...this.trackers])
		}
	}

	modalView = (modalName,data={}) =>
	{
		if(this.options.disabled)
			return noop
			
		console.log('Analytics.modalView()', { ...data })
				
		switch(this.type)
		{
			case 'gtm':
				
				ReactGTM.dataLayer(
				{
					dataLayer:
					{
						event: 'ModalView',
						...data
					}
				})

				break

			case 'ga':
			default:
				
				ReactGA.modalview(modalName)
		}
		
		
	}

	event(data={})
	{
		if(this.options.disabled)
			return noop
			
		console.log('Analytics.event()', { ...data })
				
		switch(this.type)
		{
			case 'gtm':

				ReactGTM.dataLayer(
				{
					dataLayer:
					{
						...data
					}
				})
				
				break
			
			case 'ga':
			default:
				/*
				category: 'Promotion',
				action: 'Displayed Promotional Widget',
				label: 'Homepage Thing',
				nonInteraction: true
				*/

				ReactGA.event(
					{ ...data },
					[...this.trackers]
				)
		}
	}

	onLoad = () =>
	{
		this.isLoaded =  true
		
		if(this.events.ready)
			this.events.ready()
	}

	onError = () =>
	{
		if(this.events.error)
			this.events.error()
	}

}

export default Analytics