import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react'
import { hydrate, render } from 'react-dom'

import App from './App'
import * as serviceWorker from './serviceWorker'
import { AppContainer } from 'react-hot-loader'

//import { GraphQL, GraphQLProvider } from 'graphql-react'
//const graphql = new GraphQL()

if(window.top !== window.self) 
    document.body.innerHTML = "THIS WEBSITE WILL NOT WORK INSIDE OF AN IFRAME";

const ROOT = document.getElementById('root')
const APP = (
	<AppContainer>
		{/* <GraphQLProvider graphql={graphql}> */}
			<App />
		{/* </GraphQLProvider> */}
	</AppContainer>
)

if(ROOT.hasChildNodes())
{
	hydrate(APP,ROOT)
}
else
{
	render(APP,ROOT)
}


if(module.hot)
{
	module.hot.accept('./App', () =>
	{
		render(APP,ROOT)
	})
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()