import { useState,useEffect } from 'react'
import { clamp,each,merge } from 'lodash-es'

export const noop = () => {}

export const setPageClass = (pageId,base='stun_page') =>
{
	const pattern = new RegExp(`\\b${base}_[_-\\w]+\\b`,'gi')
	const trimmed = document.documentElement.className.replace(pattern,'').trim()

	document.documentElement.className = trimmed + ` ${base}_${pageId || 'home'}`
}

export const getPageId = relativePath =>
{
	return (relativePath.split('/').slice()).splice(1,2).join('_').replace(/_$/,'').replace('_index.html','')
}

export const pageVisibilityHandlers = (action='add',on,off) =>
{
	let evt_hidden,evt_visibilityChange 

	switch(true)
	{
		// Opera 12.10 and Firefox 18 and later support
		//
		case typeof document.hidden !== 'undefined': 
			evt_hidden = 'hidden'
			evt_visibilityChange = 'visibilitychange'
			break

		case typeof document.msHidden !== 'undefined':
			evt_hidden = 'msHidden'
			evt_visibilityChange = 'msvisibilitychange'
			break

		case typeof document.webkitHidden !== 'undefined': 
			evt_hidden = 'webkitHidden'
			evt_visibilityChange = 'webkitvisibilitychange'
			break

		default:
			break
	}
	
	if(typeof document.addEventListener === 'undefined' || evt_hidden === undefined)
	{
		if(action === 'add')
		{
			if(off)
				window.addEventListener('blur',off,false)
			if(on)
				window.addEventListener('focus',on,false)
		}
		else
		{
			if(off)
				window.removeEventListener('blur',off)
			if(on)
				window.removeEventListener('focus',on)
		}
	} 
	else 
	{
		const onChange = () =>
		{
			if(document[evt_hidden])
			{
				if(off)
					off()
			} 
			else 
			{
				if(on)
					on()
			}
		}
		
		if(action === 'add')
		{
			document.addEventListener(evt_visibilityChange,onChange,false)
		}
		else
		{
			document.removeEventListener(evt_visibilityChange,onChange)
		}
	}
}

export const useScrollPosition = (target,ticker,callback) => 
{
	target = target || window
	
	const [scrollPosition, setScrollPosition] = useState(0)
	const yProp = target === window ? 'scrollY' : 'scrollTop'

	useEffect(() => 
	{
		let last_known_scroll_position = 0
		let ticking = false

		const updater = () =>
		{
			setScrollPosition(last_known_scroll_position)
			ticking = false
			if(callback)
				callback()
		}

		const handleScroll = e => 
		{
			last_known_scroll_position = target[yProp]

			if(!ticking) 
			{
				if(ticker)
				{
					ticker.addEventListener('tick',updater)
				}
				else
				{
					window.requestAnimationFrame(updater)
				}

				ticking = true
			}
		}

		if(target)
			target.addEventListener('scroll', handleScroll)

		return () => 
		{
			if(target)
				target.removeEventListener('scroll', handleScroll)

			if(ticker)
				ticker.removeEventListener('tick',updater)
		}
	},[target])

	return scrollPosition
}

export const getScrollOffsets = scrollPosition =>
{
	const scrollY = scrollPosition
	const pct = Math.max(0,scrollY / (window.innerHeight * 1))
	const opacity = clamp(1 - (1 * pct),0,1)
	
	return { scrollY, pct, opacity }
}

export const getAssetUrl = path =>
{
	if(path.indexOf('http') !== -1)
		return path
		
	return ((window.DL && window.DL.assetsBaseUrl) || '') + path
}

export const prepend = (child,parent) =>
{
	const el = typeof parent === 'string' ? document.querySelector(parent) : parent
	el.insertBefore(child,el.firstChild)
}

export const addScript = ({ 
	src,
	attributes={},
	target=document.body,
	placement='append' // or prepend
}) =>
{
	let s = document.createElement('script')
	let attrs = merge(
		{
			async: true,
			type: 'plain/text'
		},
		attributes
	)
	
	s.setAttribute('src',src)
	each(attrs, (v,k) =>
	{
		if(typeof v === 'boolean')
			v = ''
			
		s.setAttribute(k,v)
	})

	if(target)
	{
		if(typeof target ==='string')
		{
			let el = document.querySelector(target)
			if(el) target = el
		}

		if(placement === 'prepend')
		{
			prepend(s,target)
		}
		else
		{
			target.appendChild(s)
		}

	}
}

export const isFacebookBrowser = () =>
{
	var ua = navigator.userAgent || navigator.vendor || window.opera
	return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1)
}