class EventDispatcher 
{
	events = {}

	dispatch(msg) 
	{
		if(!!!msg || !!!this.events[msg] || !!!this.events[msg][0])
			return;
		
		const args = Array.prototype.splice.call(arguments, 1)

		for(let i = 0, len = this.events[msg].length; i < len; i++)
		{
			try 
			{ 
				this.events[msg][i].apply(this, args)
			} 
			catch(e) 
			{ 
				this.error('EventsDispatcher Caught Exception',e)
			}
		}
	}
	 
	on(msg,func) 
	{
		if(!this.events[msg])
			this.events[msg] = []

		this.events[msg].push(func)
	}
	 
	off(msg,func) 
	{
		if(!!!this.events[msg])
			return;

		for(let i = 0, len = this.events[msg].length; i < len; i++) 
		{
			if (this.events[msg][i] === func)
				this.events[msg].splice(i, 1)
		}
	}

	error(msg,obj) 
	{
		console.error(msg,obj)
	}
}

export default new EventDispatcher()