import React, { Fragment,useState,useContext,useCallback,useEffect,useRef } from 'react'
import { BrowserRouter as Router,Link,withRouter,useHistory } from 'react-router-dom'
import { TweenLite,TimelineLite,CSSPlugin,Expo,Power2 } from 'gsap/all'
import { map,each,filter } from 'lodash-es'
import _closest from 'closest'
import axios from 'axios'
import InfiniteScroll from 'react-infinite-scroll-component'
import { StoreContext } from 'components/Data/StoreContext'
import { getAssetUrl } from 'components/Utils/Utils'
import { cover,contain } from 'intrinsic-scale';
import { vertexShader, fragmentShader, vs_flowmap, fs_flowmap, vs_flowmap_displacement, fs_flowmap_displacement } from './shaders.js'
import './ProjectGrid.scss'

// eslint-disable-next-line no-unused-vars
const treeShakeBlocker = [CSSPlugin]

const GridImage = ({ idx,project,url }) => 
{
	const store = useContext(StoreContext)

	const init = useEffect(() =>
	{
		console.log("GridImage: init");
		addListeners();

	},[])

	const addListeners = useCallback(() =>
	{			
		store.eventDispatcher.on('showdetail',onShowDetail)
		store.eventDispatcher.on('hidedetail-complete',onHideDetail)

	},[])

	const onHideDetail = useCallback(({ event }={}) =>
	{
		store.detailShown = false;

	},[])

	const onShowDetail = useCallback(({ event,project }={}) =>
	{
		store.detailShown = true;

	},[])

	return (
		<div 
			key={idx}
			className='image-item plane'
			onClick={e =>
			{
				const payload = 
				{
					event:e,
					project
				}
				if(!store.detailShown)
					store.eventDispatcher.dispatch('showdetail', payload)
			}} 
		>
			<img 
				src={url} 
				data-sampler="planeTexture"
			/>
		</div>
	)
}

const Component = () =>
{
	const store = useContext(StoreContext)
	const [projects, setProjects] = useState([])
	const [loaded, setIsLoaded] = useState(false)
	const scrollTarget = useRef()
	const curtainsRef = useRef()
	const scrollEffect = useRef(0)

	const refs = useRef(
	{
		shaderPasses: {},
		textures: {},
		renderTargets: {},
	})
	
	const getProjects = useCallback((offset=0) =>
	{
		const nextProjects = filter(store.settings.projects.slice(offset,offset+20), { active:true })
		
		setProjects(projects.concat(nextProjects))
		setIsLoaded(true)

		return

	},[projects])

	const addEventHandlers = useCallback(() =>
	{
		console.log('ProjectGrid.addEventHandlers()')
		
	},[])

	const lerp = useCallback((start, end, amt) =>
	{
		return (1 - amt) * start + amt * end;

	},[])

	const initCurtains = useCallback(() =>
	{
		console.log('ProjectGrid.initCurtains()')
		
		// get our plane element
		const planeElements = document.querySelectorAll('.plane')
		
		if(!planeElements)
			return

		//store.lowerCurtains.disableDrawing()

		// set our initial parameters (basic uniforms)
		let uniforms = 
		{
			vertexShader: vertexShader,
			fragmentShader: fragmentShader,
			autoloadSources: true,
			watchScroll: false,
			crossOrigin: "anonymous",

			widthSegments: 1,
			heightSegments: 1
		}

		each(planeElements, (v,i) =>
		{
			let idx = 'p'+i
			
			v.setAttribute('data-idx',idx)
			
			let plane = store.lowerCurtains.addPlane(v,uniforms)
			plane.idx = idx

			plane
				.onReady(() =>
				{
					plane.mouseOver = false;

					v.addEventListener("mouseenter", e => 
					{
						plane.mouseOver = true;
					})
	
					v.addEventListener("mouseleave", e => 
					{
						plane.mouseOver = false;
					})

					// scale images to fill grid containers
					const tex = plane.textures[0]

					if(tex)
					{						
						const bbox = tex._getSizes()
						const coverTarget = cover(bbox.parentWidth,bbox.parentHeight,bbox.sourceWidth,bbox.sourceHeight);
						const xScale = Math.ceil(coverTarget.width/bbox.sourceWidth)
						const yScale = Math.ceil(coverTarget.height/bbox.sourceHeight)

						tex.setScale(xScale,yScale)
					}
				})
				.onRender(() =>
				{
					plane.updatePosition()
				})
				.onAfterResize(() =>
				{
					const { scrollLeft,scrollTop } = scrollTarget.current
					store.lowerCurtains.updateScrollValues(scrollLeft,scrollTop)

					// scale images to fill grid containers
					const tex = plane.textures[0]

					if(tex)
					{						
						const bbox = tex._getSizes()
						const coverTarget = cover(bbox.parentWidth,bbox.parentHeight,bbox.sourceWidth,bbox.sourceHeight);
						const xScale = Math.ceil(coverTarget.width/bbox.sourceWidth)
						const yScale = Math.ceil(coverTarget.height/bbox.sourceHeight)

						tex.setScale(xScale,yScale)
					}
				})
		})

		let flowMapUniforms = 
		{
			vertexShader: vs_flowmap,
			fragmentShader: fs_flowmap,
			autoloadSources: false, // don't load the image for this plane, we'll just write the mouse position on it
			depthTest: false, // we need to disable the depth test in order for the ping pong shading to work
			uniforms: {
				mousePosition: {
					name: "uMousePosition",
					type: "2f",
					value: [0, 0],
				},
				// size of the cursor
				fallOff: {
					name: "uFalloff",
					type: "1f",
					value: 1.0,
				},
				// how much the cursor should grow with time
				cursorGrow: {
					name: "uCursorGrow",
					type: "1f",
					value: 1.15,
				},
				// alpha of the cursor
				alpha: {
					name: "uAlpha",
					type: "1f",
					value: 1.0,
				},
				// how much the cursor must dissipate over time (ie trail length)
				// closer to 1 = no dissipation
				dissipation: {
					name: "uDissipation",
					type: "1f",
					value: 0.4,
				},
				// our velocity
				velocity: {
					name: "uVelocity",
					type: "2f",
					value: [0, 0],
				},
				// window aspect ratio to draw a circle
				aspect: {
					name: "uAspect",
					type: "1f",
					value: 1,
				}
			}
		}

		let flowMap = store.lowerCurtains.addPlane(store.waterPlane,flowMapUniforms);

		flowMap.mouse =
		{
			current:{x:0,y:0},
			last:{x:0,y:0},
			velocity:{x:0,y:0,length:0}
		}

		flowMap.updateVelocity = false;

		flowMap
		.onReady(() =>
		{
			window.addEventListener("mousemove", e => 
			{
				flowMap.mouse.last.x = flowMap.mouse.current.x;
				flowMap.mouse.last.y = flowMap.mouse.current.y;

				flowMap.mouse.current.x = e.clientX;
				flowMap.mouse.current.y = e.clientY;

				// divided by a frame duration (roughly)
				flowMap.mouse.velocity.x = (flowMap.mouse.current.x - flowMap.mouse.last.x) * .8;
				flowMap.mouse.velocity.y = (flowMap.mouse.current.y - flowMap.mouse.last.y) * .8;
		
				// we should update the velocity
				flowMap.updateVelocity = true;

				//console.log(flowMap.mouse.velocity);
			})

			flowMap.texture = flowMap.createTexture("uFlowMap");
			flowMap.readPass = store.lowerCurtains.addRenderTarget({ depth: false });
			flowMap.writePass = store.lowerCurtains.addRenderTarget({ depth: false });

			// update our window aspect ratio uniform
			let boundingRect = flowMap.getBoundingRect();
			flowMap.uniforms.aspect.value = boundingRect.width / boundingRect.height;
			flowMap.uniforms.fallOff.value = boundingRect.width > boundingRect.height ? boundingRect.width / 15000 : boundingRect.height / 10000;
		})
		.onRender(() =>
		{
			flowMap.updatePosition();

			// update mouse position
			let weblgMouseCoords = flowMap.mouseToPlaneCoords(flowMap.mouse.current.x, flowMap.mouse.current.y);
			flowMap.uniforms.mousePosition.value = [weblgMouseCoords.x, weblgMouseCoords.y];

			// update velocity
			if(!flowMap.updateVelocity)
			{
				flowMap.mouse.velocity.x = lerp(flowMap.mouse.velocity.x, 0, 0.5);
				flowMap.mouse.velocity.y = lerp(flowMap.mouse.velocity.y, 0, 0.5);
			}

			flowMap.mouse.velocity.length = Math.sqrt((flowMap.mouse.velocity.x * flowMap.mouse.velocity.x) + (flowMap.mouse.velocity.y * flowMap.mouse.velocity.y));

			flowMap.updateVelocity = false;

			if(flowMap.mouse.velocity.length > 2.4)
			{
				flowMap.uniforms.velocity.value = [lerp(flowMap.mouse.velocity.x, 0, 0.1), lerp(flowMap.mouse.velocity.y, 0, 0.1)];
			}
			else
			{
				flowMap.uniforms.velocity.value = [0,0]
			}

			// update the render target
			if(flowMap.writePass)
			{
				flowMap.setRenderTarget(flowMap.writePass);
			}
		
		})
		.onAfterRender(() =>
		{
			// swap FBOs and update texture
			if(flowMap.readPass && flowMap.writePass)
			{
				// swap read and write passes
				let tempFBO = flowMap.readPass;
				flowMap.readPass = flowMap.writePass;
				flowMap.writePass = tempFBO;
		
				// apply new texture
				flowMap.texture.setFromTexture(flowMap.readPass.textures[0]);
			}
		})
		.onAfterResize(() =>
		{
			console.log("Plane:onAfterResize");

			const { scrollLeft,scrollTop } = scrollTarget.current
			store.lowerCurtains.updateScrollValues(scrollLeft,scrollTop)

			// update our window aspect ratio uniform
			let boundingRect = flowMap.getBoundingRect();
			flowMap.uniforms.aspect.value = boundingRect.width / boundingRect.height;
			flowMap.uniforms.fallOff.value = boundingRect.width > boundingRect.height ? boundingRect.width / 15000 : boundingRect.height / 10000;
		})


		let flowMapDisplacementUniforms = {
			vertexShader: vs_flowmap_displacement,
			fragmentShader: fs_flowmap_displacement
		};
		
		let shaderPass = store.lowerCurtains.addShaderPass(flowMapDisplacementUniforms);

		shaderPass.flowMapTexture = new window.Curtains.Texture(shaderPass, {
			index: shaderPass.textures.length,
			sampler: "uFlowTexture",
			isFBOTexture: false,
		});
	
		shaderPass.textures.push(shaderPass.flowMapTexture);

		shaderPass.onRender(() => {

			shaderPass.flowMapTexture.setFromTexture(flowMap.texture);
		})

	},[loaded,vertexShader,fragmentShader])

	const init = useCallback(() =>
	{
		if(!loaded)
			return
			
		console.log('ProjectGrid.init()')
		
		initCurtains()
		addEventHandlers()
		
	},[loaded,initCurtains,addEventHandlers])
	
	useEffect(() => 
	{
		getProjects()
		
		scrollTarget.current = document.querySelector('.page')
		scrollTarget.current.addEventListener('scroll', e =>
		{
			// get scroll deltas to apply the effect on scroll
			var delta = store.lowerCurtains.getScrollDeltas();

			// manually update planes positions
			//
			const { scrollLeft,scrollTop } = e.target
			store.lowerCurtains.updateScrollValues(scrollLeft,scrollTop)
			
			each(store.lowerCurtains.planes, v =>
			{
				v.updateScrollPosition()

				/*
				// apply additional translation, scale and rotation
				applyPlanesParallax(i);
				*/
			})

		},{ passive: true })

	},[])

	useEffect(() =>
	{
		if(loaded && store.assetsLoaded)
			setTimeout(init,0)

	},[loaded,store.assetsLoaded])

	if(!loaded)
		return null

	return (
		<Fragment>
			<InfiniteScroll
				dataLength={projects.length}
				scrollableTarget={scrollTarget.current}
				_next={() => { /*fetchImages(3)*/ }}
				_hasMore={true}
				_loader=
				{
					<h4 className='end-of-content'>Loading...</h4>
				}
				_endMessage=
				{
					<p style={{textAlign: 'center'}}>
						<b>Yay! You have seen it all</b>
					</p>
				}
			>
				<div className="image-grid">
				{
					loaded ?
						projects.map((project,idx) => {
							
							const img = filter(store.settings.assets, { id:project.thumbid })[0]
							if(!img)
								return null

							return (
								<GridImage
									key={`p_${project.id}_${idx}`}
									idx={idx}
									project={project}
									url={img.url}
								/>
							)
						})
						: 
						''
				}
				</div>
			</InfiniteScroll>
		</Fragment>
	)
}

export default withRouter(Component)