import React,{ Fragment, useContext, useCallback, useEffect, useRef, useState } from 'react'
import { Spring,Transition,animated,config } from 'react-spring/renderprops'
import { StoreContext } from 'components/Data/StoreContext'
import { map,each,filter } from 'lodash-es'
import { vertexShader, fragmentShader } from './shaders.js'
import { TweenLite,TimelineLite,CSSPlugin,Expo } from 'gsap/all'
import Flickity from 'flickity'
import { ReactComponent as Zoom } from 'assets/images/zoom.svg'

import './ModalGrid.scss'

require('flickity-imagesloaded')
require('flickity-fade')

const ModalGrid = props =>
{
	const store = useContext(StoreContext)
	const [title, setTitle] = useState("Title")
	const [client, setClient] = useState("Client")
	const [description, setDescription] = useState("Description")
	const [partners, setPartners] = useState("Partners")
	const [role, setRole] = useState("Development")
	const [siteUrl, setSiteUrl] = useState("")
	const [slides, setSlides] = useState([])
	const [dialogShown, setDialogShown] = useState(false)

	const contentRef = useRef();
	const gridModalRef = useRef();

	const refs = useRef(
	{
		flickity: null,
		mouseXNormalized: 0.0,
		transitionTimes: {
			background: 0.375,
			content: 0.375,
		}
	})

	const init = useEffect(() =>
	{
		console.log("ModalGrid: init");

		if(!store.assetsLoaded)
			return
				
		addListeners()

		transitionOutComplete();
		
	},[store.assetsLoaded])

	const addListeners = useCallback(() =>
	{			
		//window.addEventListener('mousemove',onMouseMove);
		store.eventDispatcher.on('showdetail',onShowDetail)

	},[])

	const onMouseMove = useCallback((e) =>
	{
		let centeredX = e.clientX - (window.innerWidth/2)
		let centeredXNormalized = centeredX / (window.innerWidth/2);
		let xNormalized = e.clientX / window.innerWidth;

		refs.current.mouseXNormalized = xNormalized;

	},[])

	const onShowDetail = useCallback(({ event,project }={}) =>
	{
		const { client,title,partners,role,blurb,imageids,url } = project
		
		TweenLite.set(gridModalRef.current, { autoAlpha:1 })
		TweenLite.fromTo(gridModalRef.current, 1, { x:'50%' }, { x:'0%', ease:Expo.easeOut });	

		console.log("ModalGrid:onShowDetail", store.settings.assetsById, { event,client,title,partners,role,blurb,imageids });

		setTitle(title);
		setClient(client);
		setDescription(blurb);
		setPartners(partners);
		setRole(role);
		setSiteUrl(url);
		setSlides(imageids);
		setDialogShown(true);

	},[])

	const closeModal = useCallback(() =>
	{			
		transitionOutStart();
		TweenLite.to(gridModalRef.current, 1, { x:'50%', ease:Expo.easeOut, delay: 0.1, onComplete:transitionOutComplete });	
		
	},[])

	const transitionInComplete = useCallback(() =>
	{			

	},[])

	const transitionOutStart = useCallback(() =>
	{	
		store.eventDispatcher.dispatch('hidedetail-start', {});

	},[])
	
	const transitionOutComplete = useCallback(() =>
	{	
		TweenLite.set(gridModalRef.current,{ x:'100%' });
		store.eventDispatcher.dispatch('hidedetail-complete', {});
		
		setDialogShown(false);

	},[])

	const goBig = useCallback(url =>
	{
		alert('go big! '+url)
	})

	useEffect(() =>
	{
		if(dialogShown)
		{
			refs.current.flickity = new Flickity( '.slider', 
			{
				wrapAround: true,
				prevNextButtons: false,
				pageDots: true,
				lazyLoad: 2,
				imagesLoaded: true,
				autoPlay: 7000,
				fade: true,
				selectedAttraction: 0.2,
				friction: 0.8
			})
		}
		else
		{
			if(refs.current.flickity)
				refs.current.flickity.destroy()
		}

	},[dialogShown])

	return (
		<div id='grid-modal'
			ref={gridModalRef}
		>
			<div className='bg' onClick={closeModal}></div>
			<div className='container'>
				<div className='content'
					ref={contentRef}
				>
					<button className='x' onClick={closeModal}>X</button>
					
					<div className='slider'>
						{
							slides.map((imageid,idx) => (
								<div 
									key={idx}
									className='slide' 
								>
									<img 
										src={store.settings.assetsById[imageid].url} 
									/>
									<button
										className='zoom'
										onClick={e => goBig(store.settings.assetsById[imageid].url)}
									>
										<Zoom />
									</button>
								</div>
							))
						}
					</div>

					<div className='info'>
						<header>
							<h3>{title}</h3>
						</header>
						
						<div className='meta'>
							<ol>
								<li><span className='label'>Client</span>: {client}</li>
								<li><span className='label'>Agency</span>: {partners}</li>
								<li><span className='label'>Role</span>: {role}</li>
								<li><span className='label'>Description</span>:<br />{ description ? map(description, (v,idx) => { return <p key={'info-description-'+idx}>{description}</p> }) : null }</li>
								{ siteUrl ? map(siteUrl, (v,idx) => { 
									
									return <li key={'info-url-'+idx}><a href={v.url ? v.url : '#'} target={v.url ? '_blank' : ''} onClick={e => { return !!v.url }}>{v.name || 'Website'}</a></li> }) : null 
								}
							</ol>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ModalGrid