import { useEffect,useState,useCallback } from 'react'
import { isIOS,isChrome } from 'react-device-detect'
import { debounce,delay } from 'lodash-es'

const useOrientation = () => 
{
	const isLandscape = useCallback(() => window.innerWidth > window.innerHeight,[])
	const [_isLandscape,setIsLandscape] = useState(isLandscape())

	useEffect(() => 
	{
		const handleResize = () => setIsLandscape(isLandscape())
		
		let fn = debounce(handleResize,10)
		if(isIOS && isChrome)
			fn = debounce(handleResize,500)

		window.addEventListener('resize',fn,false)
		
		return () => 
		{
			window.removeEventListener('resize',fn)
		}
		
	},[])

	return {
		isLandscape: _isLandscape,
		isPortrait: !_isLandscape
	}
}

export default useOrientation