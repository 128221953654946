/* global PIXI */
import React, { Fragment,useState,useEffect,useCallback,useContext,useRef } from 'react'
import { BrowserRouter as Router,withRouter } from 'react-router-dom'
import { TweenLite,TimelineLite,CSSPlugin,Expo } from 'gsap/all'
import Helmet from 'react-helmet'
import { each } from 'lodash-es'
import innerHeight from 'ios-inner-height'
import { isMobileOnly, isSafari } from 'react-device-detect'
import useOrientation from 'components/CustomHooks/useOrientation'

import { StoreContext } from 'components/Data/StoreContext'
import { setPageClass,getAssetUrl,getPageId } from 'components/Utils/Utils'

import Nav from './Nav/Nav'
//import NavLinks from './NavLinks/NavLinks'
import ModalGrid from 'components/ModalGrid/ModalGrid'
import ModalContact from 'components/ModalContact/ModalContact'
//import Header from './Header/Header'
import Footer from './Footer/Footer'
import Main from './Main'
import Pixi from 'components/Pixi/Pixi'
import PixiSound from 'pixi-sound'
import VideoPlayer from 'components/VideoPlayer/VideoPlayer'
import Loader from 'components/Loader/Loader'
import Roadblock from 'components/Roadblock/Roadblock'
//import Logo from 'components/UI/Logo'

// eslint-disable-next-line
const treeShakeBlocker = [CSSPlugin,Router]

const Layout = props =>
{
	const store = useContext(StoreContext)
	const [_videoPlayerEnabled,setVideoPlayerEnabled] = useState(false)
	const [_videoPlayerType,setVideoPlayerType] = useState(false)
	
	const { isLandscape } = useOrientation()
	const waterPlaneRef = useRef();

	//window.datastore = store

	if(store.assetsLoaded)
		console.log('Layout => store.assetsLoaded == TRUE')
	else
		console.log('Layout => store.assetsLoaded == FALSE')

	// class out up to 2 levels deep...
	//
		store.pageId = getPageId(props.location.pathname)

	// craft cms preview...
	//
		// let m = document.location.href.match(/\bx-craft-live-preview=([^&]+)/)
		// let isCraftPreview = m && m[1]
		// if(isCraftPreview)
		// {
		// 	store.isCraftPreview = true
		// 	document.documentElement.classList.add('craft-preview')
		// }

	const [_location,setLocation] = useState(store.pageId)
	const [_navOpen,setNavOpen] = useState(false)
	const [_initEventHandlers,setInitEventHandlers] = useState(false)
	//const [_assetsLoaded,setAssetsLoaded] = useState(false);
	
	
	// CALLBACKS
	//
	store.playSound = useCallback(id =>
	{
		const sound = store.loader.assets[id]
		if(sound)
			sound.play()
	},[])
	
	store.stopSound = useCallback(id =>
	{
		const sound = store.loader.assets[id]
		if(sound)
			sound.pause()
		else
			PixiSound.sound.pauseAll()
	},[])
	
	store.showVideoPlayer = useCallback(videoType =>
	{
		document.documentElement.classList.add('video-playing')
		setVideoPlayerType(videoType)
		setVideoPlayerEnabled(true)
		store.videoPlayerActive = true

	},[])

	store.hideVideoPlayer = useCallback(() =>
	{
		document.documentElement.classList.remove('video-playing')
		setVideoPlayerEnabled(false)
		store.videoPlayerActive = false

	},[])

	store.addScrollTracking = useCallback((target,listener) =>
	{
		store.removeScrollTracking()
		
		let newTarget = document.querySelector(target)
		if(newTarget)
		{
			store.scrollTarget = newTarget
			
			if(listener)
				store.addScrollListener(listener)

			store.scrollTarget.addEventListener('scroll',onScroll)
			store.scrollTarget.scrollTop = 0.001
		}
			
	},[])

	store.removeScrollTracking = useCallback(() =>
	{
		if(store.scrollTarget)
		{
			store.scrollTarget.removeEventListener('scroll',onScroll)
			store.scrollTarget = null
		}
	},[])
	
	store.addScrollListener = useCallback(fn =>
	{
		if(fn && store.scrollListeners.indexOf(fn) === -1)
			store.scrollListeners.push(fn)
			
	},[])

	store.removeScrollListener = useCallback(fn =>
	{
		let idx = fn && store.scrollListeners.indexOf(fn)
		if(idx !== -1)
		{
			store.scrollListeners.splice(idx,1)
		}
	},[])

	const init = useCallback(() =>
	{
		console.log("Layout:init()")

		store.lowerCurtains = new window.Curtains({
			container: 'lower-canvas'
		})

		store.waterPlane = waterPlaneRef.current;
		
		addListeners()

	},[])

	const onScroll = useCallback(e =>
	{
		const windowHeight = innerHeight()

		each(store.scrollListeners, fn =>
		{
			fn && fn(
			{ 
				evt: e,
				scrollTop: e.target.scrollTop,
				vh: windowHeight 
			})
		})

	},[])

	const onResize = useCallback(() =>
	{
		const rootContainer = document.querySelector('#root')
		const width = window.innerWidth
		let height = window.innerHeight

		if(isMobileOnly && isSafari)
		{
			height = innerHeight()

			/*if(window.Modernizr.ios13)
			{
				height = innerHeight() - 110
			}
			else
			{
				height = innerHeight() - 80
			}*/

			rootContainer.style.height = height+'px'
		}

		console.log('Layout.onResize()', { width,height })

		store.eventDispatcher.dispatch('resize', 
		{ 
			width: width,
			height: height
		})

	},[])

	const addListeners = useCallback(() =>
	{
		console.log('Layout.addListeners()')
		
		store.detailShown = false;
		store.eventDispatcher.on('showdetail',onShowModal)
		store.eventDispatcher.on('showcontact',onShowModal)

		store.eventDispatcher.on('hidedetail',onHideModal)
		store.eventDispatcher.on('hidedetail-start',onHideModalStart)
		store.eventDispatcher.on('hidedetail-complete',onHideModal)
		store.eventDispatcher.on('hidecontact',onHideModal)

		window.addEventListener('resize',onResize)
		onResize()

	},[onResize])

	const onShowModal = useCallback(({ event,client,title,description }={}) =>
	{
		TweenLite.to('#main', 1, { x:'-50%', delay: 0.1, ease: Expo.easeOut });

	},[])

	const onHideModal = useCallback(({ event }={}) =>
	{
		//TweenLite.to('#main', 0.5, { x:'0vw', ease: Expo.easeIn })
	},[])

	const onHideModalStart = useCallback(({ event }={}) =>
	{
		TweenLite.to('#main', 1, { x:'0%', ease: Expo.easeOut });

	},[])

	// SIDE EFFECTS
	//
		useEffect(() =>
		{
			//TweenLite.set('#main,#nav,#footer,#flight-tracker', { autoAlpha:0 })
			TweenLite.to('#root', 0.5, { autoAlpha:1, delay:0 })
			
		},[])

		useEffect(() =>
		{
			onResize()
			
		},[isLandscape])

		useEffect(() =>
        {
			setPageClass(store.pageId)
			store.hideVideoPlayer()

		}, [store.pageId])

		// Add Event Handlers
		//
		useEffect(() => 
		{
			setInitEventHandlers(true)

		},[_initEventHandlers])

		
		// UI updates on page change
		//
		useEffect(() => 
		{
			if(props.location.pathname !== _location)
			{
				setNavOpen(false)
				setLocation(props.location.pathname)

				store.history.push({
					pageId: store.pageId,
					uri: props.location.pathname
				})

				setPageClass(store.pageId)
			}

		},[_location,props.location.pathname,store.history,store.pageId])

		useEffect(() =>
		{
			init()
			
		},[])

	
	return (
		<Fragment>
			
			<Helmet>
				<meta charSet='utf-8' />
				<title>HBO :: Avenue 5</title>
				<meta name='title' content='HBO :: Avenue 5' />
				<meta name='description' content='' />
				<meta name='keyword' content='hbo,tv,show,sci-fi,comedy' />
			</Helmet>

			<div id="lower-canvas"></div>
			<div 
				id="water-plane" 
				ref={waterPlaneRef}
			/>
			{/*<Pixi />*/}

			<Nav 
				isOpen={_navOpen}
				onClick={e => setNavOpen(true)}
				onMouseLeave={e => setNavOpen(false)}
			/>

			<Main />

			{/* <Footer /> */}

			{/* <Logo /> */}

			<ModalGrid/>

			{/* <ModalContact/> */}

			<Roadblock />
			
			{/*
			<VideoPlayer
				enabled={_videoPlayerEnabled}
				type={_videoPlayerType}
			/>
			*/}

			{ !store.assetsLoaded || !store.siteRevealed ? <Loader debug={false} /> : null }

		</Fragment>
	)
}

export default withRouter(Layout)