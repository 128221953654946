//import React, { useRef,useContext,useCallback } from 'react'
import { each,bindAll,keys,size } from 'lodash-es'
import { TweenLite,TimelineLite,Expo } from 'gsap/all'
import * as PIXI from 'pixi.js'

// eslint-disable-next-line
const treeShakeBlocker = []

export default class Jitter
{	
	constructor()
	{
		this.percent = 0.0;

		const fragSrc =  `
			varying vec2 vTextureCoord;
			uniform sampler2D uSampler;
			uniform float percent;

			vec4 getColor(in vec2 uv)
			{
				return texture2D(uSampler, uv);
			}

			vec4 effect(in vec2 uv)
			{
				vec2 block = floor(uv.xy / vec2(16));
				vec2 uv_noise = block / vec2(64);
				uv_noise += floor(vec2(percent) * vec2(1200.0, 3500.0)) / vec2(64);
				vec2 dist = percent > 0.0 ? (fract(uv_noise) - 0.5) * 0.3 * percent : vec2(0.0);
				vec2 red = uv + dist * 0.2;
				vec2 green = uv + dist * .3;
				vec2 blue = uv + dist * .5;
				vec2 alpha = uv + dist * .1;
			
				return vec4(getColor(red).r,getColor(green).r,getColor(blue).r,getColor(alpha).a);
			}

			void main(void)
			{
				gl_FragColor = effect(vTextureCoord);
			}
		`;

		this.filter = new PIXI.Filter(null, fragSrc, {percent:0.0});
		this.filter.autoFit = true;
		this.update();
	}

	update()
	{
		this.filter.uniforms.percent = this.percent;
	}
}