//import React, { useRef,useContext,useCallback } from 'react'
import { each,bindAll,keys,size } from 'lodash-es'
import { TweenLite,TimelineLite,Expo } from 'gsap/all'
import * as PIXI from 'pixi.js'

// eslint-disable-next-line
const treeShakeBlocker = []

export default class Hexagonal
{	
	constructor()
	{
		this.percent = 0.0;
		this.steps = 100;
		this.horizontalHexagons = 20;
		this.ratio = 1.0;

		const fragSrc =  `
			varying vec2 vTextureCoord;
			uniform sampler2D uSampler;
			uniform float percent;
			uniform int steps;
			uniform float horizontalHexagons;
			uniform float ratio;

			struct Hexagon
			{
				float q;
				float r;
				float s;
			};

			vec4 getColor(in vec2 uv)
			{
				return texture2D(uSampler, uv);
			}

			Hexagon createHexagon(float q, float r)
			{
				Hexagon hex;
				hex.q = q;
				hex.r = r;
				hex.s = -q - r;
				return hex;
			}

			Hexagon roundHexagon(Hexagon hex)
			{
				float q = floor(hex.q + 0.5);
				float r = floor(hex.r + 0.5);
				float s = floor(hex.s + 0.5);

				float deltaQ = abs(q - hex.q);
				float deltaR = abs(r - hex.r);
				float deltaS = abs(s - hex.s);

				if (deltaQ > deltaR && deltaQ > deltaS)
					q = -r - s;
				else if (deltaR > deltaS)
					r = -q - s;
				else
					s = -q - r;

				return createHexagon(q, r);
			}

			Hexagon hexagonFromPoint(vec2 point, float size)
			{
				point.y /= ratio;
				point = (point - 0.5) / size;
				
				float q = (sqrt(3.0) / 3.0) * point.x + (-1.0 / 3.0) * point.y;
				float r = 0.0 * point.x + 2.0 / 3.0 * point.y;

				Hexagon hex = createHexagon(q, r);
				return roundHexagon(hex);
			}

			vec2 pointFromHexagon(Hexagon hex, float size)
			{
				float x = (sqrt(3.0) * hex.q + (sqrt(3.0) / 2.0) * hex.r) * size + 0.5;
				float y = (0.0 * hex.q + (3.0 / 2.0) * hex.r) * size + 0.5;
				
				return vec2(x, y * ratio);
			}

			vec4 effect (in vec2 uv)
			{
				float dist = percent;
				dist = steps > 0 ? ceil(dist * float(steps)) / float(steps) : dist;
				
				float size = (sqrt(3.0) / 3.0) * dist / horizontalHexagons;
				
				vec2 point = dist > 0.0 ? pointFromHexagon(hexagonFromPoint(uv, size), size) : uv;

				return getColor(point);
			}

			void main(void)
			{	
				gl_FragColor = effect(vTextureCoord);
			}
		`;

		this.filter = new PIXI.Filter(null, fragSrc, { steps:100, horizontalHexagons:20, ratio:1.0, percent:0.0});
		this.filter.autoFit = true;
		this.update();
	}

	update()
	{
		this.filter.uniforms.steps = this.steps;
		this.filter.uniforms.horizontalHexagons = this.horizontalHexagons;
		this.filter.uniforms.ratio = this.ratio;
		this.filter.uniforms.percent = this.percent;
	}
}