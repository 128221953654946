import React, { useEffect,useRef,useContext,useCallback } from 'react'
import { StoreContext } from 'components/Data/StoreContext'
import { filter } from 'lodash-es'
import { TweenLite,TimelineLite, Power2, Power1, Expo } from 'gsap/all'
import * as PIXI from 'pixi.js'
import BadTVFilter from 'components/Pixi/Filters/BadTV';
import CRTFilter from 'components/Pixi/Filters/CRT';
import { ReactComponent as Logo} from 'assets/images/logo2.svg';
//import { ReactComponent as Logo} from 'assets/images/logo.svg';
//import { ReactComponent as LogoType} from 'assets/images/logotype.svg';


// eslint-disable-next-line
const treeShakeBlocker = []

const LogoComponent = props => 
{
	const store = useContext(StoreContext)
	const data = useRef(null)

	const pixi = useRef({
		renderer:		null,
		stage:			null,
		containers:		{},
		resources:		{},
		textures:		{},
		sprites:		{},
		graphics:		{},
		filters:		{},
	})

	const refs = useRef({
		debug:			false,
		dragging:		null,
		mouseData:		null,
		lastRender:		0,
		time: {
			previous: 0.0,
			current: 0.0,
			delta: 0
		},
		timer:			null
	})

	const init = useCallback(() =>
	{
		const target = refs.current.pixiWrapper
		
		if(refs.current.debug)
			console.log('Logo.init()',target)
		
		pixi.current.renderer = new PIXI.Renderer(
		{
			backgroundColor: 0x000000,
			transparent: true,
			antialias: true,
			forceFXAA: true,
			autoDensity: true,
			width: 95,
			height: 172,
		})
		
		pixi.current.stage = new PIXI.Container()

		pixi.current.renderer.view.id = 'pixi-logo'

		target.appendChild(pixi.current.renderer.view)

	},[])

	const destroy = useCallback(() =>
	{
		// cleanup...
	},[])

	const addSprite = useCallback((key, img, position = {x:0, y:0}, anchor = {x:0,y:0}, blendMode = PIXI.BLEND_MODES.NORMAL) =>
	{
		pixi.current.sprites[key] = new PIXI.Sprite.from(img)
		pixi.current.stage.addChild(pixi.current.sprites[key])
		pixi.current.sprites[key].position.set(position.x,position.y);
		pixi.current.sprites[key].anchor.set(anchor.x,anchor.y);
		pixi.current.sprites[key].blendMode = blendMode
		pixi.current.sprites[key].alpha = 0
	},[])

	const build = useCallback(() =>
	{
		pixi.current.graphics["background"] = new PIXI.Graphics()
			.beginFill(0x000000,0.0)
			.drawRect(0, 0, pixi.current.renderer.width, pixi.current.renderer.height)
			.endFill();

		pixi.current.stage.addChild(pixi.current.graphics["background"]);

		pixi.current.sprites["logo"] = new PIXI.Sprite.from(store.loader.assets['logo'].data);
		pixi.current.sprites["logo"].anchor.set(0.5,0.0);
		pixi.current.sprites["logo"].scale.set(pixi.current.renderer.width/pixi.current.sprites["logo"].width*.9);
		pixi.current.sprites["logo"].x = pixi.current.renderer.width/2;
		pixi.current.stage.addChild(pixi.current.sprites["logo"]);
		
		pixi.current.sprites["logoType"] = new PIXI.Sprite.from(store.loader.assets['logo_type'].data);
		pixi.current.sprites["logoType"].anchor.set(0.5,1.0);
		pixi.current.sprites["logoType"].scale.set(pixi.current.renderer.width/pixi.current.sprites["logoType"].width * .9);
		pixi.current.sprites["logoType"].x = pixi.current.renderer.width/2;
		pixi.current.sprites["logoType"].y = pixi.current.renderer.height;
		pixi.current.stage.addChild(pixi.current.sprites["logoType"]);

		pixi.current.filters["badTV"] = new BadTVFilter();
		pixi.current.filters["badTV"].percent = 0.3;
		pixi.current.filters["badTV"].speed = 1.5;
		pixi.current.filters["badTV"].rollSpeed = 0;
		pixi.current.filters["badTV"].distortion = 0.5;
		pixi.current.filters["badTV"].distortion2 = 5;

		pixi.current.filters["crt"] = new CRTFilter();

		//pixi.current.stage.filters = [pixi.current.filters["badTV"].filter,pixi.current.filters["crt"].filter];

		addListeners();

	},[])

	const addListeners = useCallback(() =>
	{			
		// It is necessary to add the listener with: "this, false, 1" because if not renderLoop gets called after
		// the listener is removed.  The "1" sets the listener with a positive priority (meaning its called earlier)
		// It appears that normally the GSAP ticker always updates AFTER the core engine does its updates. (Which would explain why it gets called even after removeEventlistener is called) 
		// see: https://greensock.com/forums/topic/16174-tweenliteticker-does-not-work-as-expected/
		TweenLite.ticker.addEventListener('tick', renderLoop, this, false, 1);
		store.eventDispatcher.on('resize',onResize)
		onResize()

	},[onResize])

	const removeListeners = useCallback(() =>
	{			
		TweenLite.ticker.removeEventListener('tick', renderLoop);
		store.eventDispatcher.off('resize',onResize)

	},[onResize])

	const renderLoop = useCallback(() =>
	{
		refs.current.time.previous = refs.current.time.current;
		refs.current.time.current = TweenLite.ticker.time;
		refs.current.time.delta = refs.current.time.current - refs.current.time.previous;

		pixi.current.filters["badTV"].time = refs.current.time.current + 0.1 * refs.current.time.delta;
		pixi.current.filters["crt"].time = refs.current.time.delta * 2000;

		pixi.current.filters["badTV"].update();
		pixi.current.filters["crt"].update();

		pixi.current.renderer.render(pixi.current.stage);
	},[])

	const onResize = useCallback(({ width,height }={}) =>
	{

	},[])

	const onClick = useCallback(e =>
	{
		//store.eventDispatcher.dispatch('showcontact', {event:e});
	},[])

	useEffect(() =>
	{
		//init()

	},[])

	useEffect(() =>
	{
		// if(store.assetsLoaded)
		// 	build()
		
	},[store.assetsLoaded])

	useEffect(() =>
	{
		if(store.pages.length)
			data.current = filter(store.pages, { slug: store.pageId })[0]
		
	},[store.pages])
	
	return (
		<div 
			id='logo'
			ref={e => { if(e) refs.current.pixiWrapper = e }}
			onClick={onClick}
		>
			<Logo className='logo-image' />
			{/* <LogoType className='logo-type' /> */}
		</div>
	)
}

export default LogoComponent