import React, { Fragment,useState,useContext,useCallback,useEffect,useRef } from 'react'
import { BrowserRouter as Router,Link,withRouter,useHistory } from 'react-router-dom'
import { TweenLite,TimelineLite,CSSPlugin,Expo,Power2 } from 'gsap/all'
import { map } from 'lodash-es'
import _closest from 'closest'
import { StoreContext } from 'components/Data/StoreContext'
import { getAssetUrl } from 'components/Utils/Utils'
//import SocialButton from 'components/Social/SocialButton'
import NavLogo from 'components/Nav/NavLogo'
import './Nav.scss'

// eslint-disable-next-line no-unused-vars
const treeShakeBlocker = [CSSPlugin]

const Nav = props => 
{
	const store = useContext(StoreContext)
	const history = useHistory();
	const [_isOpen,setIsOpen] = useState(props.isOpen)

	// CALLBACKS
	//
		const openNav = useCallback(() =>
		{
			store.playSound('appear')
			setIsOpen(true)
			
		},[])

		const closeNav = useCallback(() =>
		{
			if(_isOpen)
				store.playSound('chirp')

			setIsOpen(false)
			
		},[])

		const toggleMenu = useCallback(() =>
		{
			if(_isOpen)
			{
				closeNav()
			}
			else
			{
				openNav()
			}

		},[_isOpen])

		const onHover = useCallback(() =>
		{
			//store.playSound('hover')

		},[])

		const onHoverOff = useCallback(() =>
		{
			//store.stopSound('hover')
			
		},[])

		const onClick = useCallback(e =>
		{
			const el = _closest(e.target,'.navbar-item ',true)
			
			store.analytics.event({
				event: 'User Click Navigation',
				label: el.querySelector('.label').textContent
			})

			store.eventDispatcher.dispatch('nav-click', { pageId: el.dataset.pageId })
			
			store.playSound('click')
			
			closeNav()
			onHoverOff()
			
		},[])

		const onResize = useCallback(({ width,height }={}) =>
		{
			width = width || window.innerWidth
			
			if(width > 900)
			{
				TweenLite.set('.menu, .menu a', { clearProps:'transform'})
				closeNav()
			}
		},[closeNav])
		
	
		const navLogoClick = useCallback(() =>
		{	
			store.analytics.event({
				event: 'User Click Navigation',
				label: 'Homepage'
			})
			
			store.playSound('click')
			closeNav()
			history.push("/");
			
		},[])
		
	// EFFECTS
	//
		useEffect(() =>
		{
			store.eventDispatcher.on('resize',onResize)

		},[onResize])

		useEffect(() =>
		{
			closeNav()

		},[props.location.pathname,closeNav])
		
		useEffect(() =>
		{
			const TL = new TimelineLite()
			
			if(_isOpen)
			{
				TL.to('.menu', 1, { y:'0%', ease: Expo.easeOut })
				TL.staggerFromTo('.menu .navbar-item',0.75, { x:'-350%' }, { force3D:true, x:'0%', ease:Power2.easeOut },0.05,0.25)

				document.documentElement.classList.add('--nav-open')
			}
			else if (window.innerWidth <= 900)
			{
				TL.to('.menu', 0.375, { y:'-100%', ease: Expo.easeIn })

				document.documentElement.classList.remove('--nav-open')
			}
			else
			{
				TweenLite.set('.menu, .menu a', { clearProps:'transform'})

				document.documentElement.classList.remove('--nav-open')
			}

		},[_isOpen])
	
	return null
	
	return (
		<nav 
			id="nav" 
			className="navbar" 
			role="navigation" 
			aria-label="main navigation"
		>
			<div 
				className={`burger ${_isOpen ? 'open' : ''}`}
				onClick={toggleMenu}
			>
				<span className='bar' aria-hidden="true"></span>
				<span className='bar' aria-hidden="true"></span>
				<span className='bar' aria-hidden="true"></span>
			</div>
			
			<div className="logo">
				{/* <NavLogo onClick={navLogoClick}/> */}
			</div>
			
			<div className="menu">
				<div className="left">
					{/* 
					<Link 
						key={v.label}
						to={v.path} 
						className={`navbar-item ${v.klass || ''} ${v.path ===  props.location.pathname ? 'current' : ''}`}
						data-page-id={v.id}
						onMouseEnter={onHover}
						onMouseLeave={onHoverOff}
						onClick={onClick}
					>
						<div className='bg'></div>
						<span 
							className='label'
						>
							{v.label}
						</span>
					</Link> 
					*/}
				</div>
			</div>
		</nav>
	)
}

export default withRouter(Nav)