/* global */
import React, { Fragment,useEffect,useContext,useRef } from 'react'
import Helmet from 'react-helmet'
import { filter } from 'lodash-es'
import { StoreContext } from 'components/Data/StoreContext'

import './NotFound.scss'

// eslint-disable-next-line
const treeShakeBlocker = []

const NotFound = props =>
{
	const store = useContext(StoreContext)
	const data = useRef(null)
	const cssBaseSelector = '.page[data-page-id="404"]'

	useEffect(() => 
	{
		store.analytics.pageView('/notfound')
		store.addScrollTracking(`${cssBaseSelector}`)

	},[])

	useEffect(() =>
	{
		if(store.pages.length)
		{
			data.current = filter(store.pages, { slug: store.pageId })[0]
		}
		
	},[store.pages])
	
	return (
		<Fragment>
			<Helmet>
				<title>{store.settings.metaTags.notfound.pageTitle}</title>
				<meta name='title' content={store.settings.metaTags.notfound.metaTitle} />
				<meta name='description' content={store.settings.metaTags.notfound.metaDescription} />
				<link rel='canonical' href={`${process.env.REACT_APP_SITE_URL}/`} />
			</Helmet>
			<div 
				data-page-id='404' 
				className='page'
				style={props.styles}
			>
				<div className='content'>
					<p dangerouslySetInnerHTML={{ __html:store.settings.notfound }}></p>
				</div>
			</div>
		</Fragment>
	)
}

export default NotFound