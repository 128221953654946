import React, { useState,useEffect,useCallback,useRef,useContext } from 'react'
import { BrowserRouter as Router,withRouter } from 'react-router-dom'
import { TweenLite,Expo,Linear } from 'gsap/all'
import { each,filter,keyBy } from 'lodash-es'
import { fit } from 'fit.js'
import preloader from 'preloader'
import * as PIXI from 'pixi.js'
import PixiSound from 'pixi-sound'
import { getAssetUrl } from 'components/Utils/Utils'
import { StoreContext } from 'components/Data/StoreContext'
import ProgressBar from 'progressbar.js'
import { isMobileOnly, isSafari, isIOS, isAndroid, isChrome, isFirefox } from 'react-device-detect'
import { isFacebookBrowser } from 'components/Utils/Utils'
import BadTVFilter from 'components/Pixi/Filters/BadTV';
import HexagonalFilter from 'components/Pixi/Filters/Hexagonal';
import JitterFilter from 'components/Pixi/Filters/Jitter';
import KawaseBlurFilter from 'components/Pixi/Filters/KawaseBlur';
import AsciiFilter from 'components/Pixi/Filters/Ascii';
import CRTFilter from 'components/Pixi/Filters/CRT';


import './Loader.scss'


const Loader = (props) =>
{
	const store = useContext(StoreContext)
	const [_isLoaded,setIsLoaded] = useState(false)
	const [_pctLoaded,setPctLoaded] = useState(0)
	const containerRef = useRef(null)

	const pixi = useRef({
		renderer:		null,
		stage:			null,
		containers:		{},
		resources:		{},
		textures:		{},
		sprites:		{},
		graphics:		{},
		filters:		{},
		timelines:		{},
	})
	
	const refs = useRef(
	{
		loader: null,
		mouseXNormalized: 0.0,
		time: {
			previous: 0.0,
			current: 0.0,
			delta: 0
		},
		minLoadTime: 3.0,
		tweenLoadProgress: 0.0,
		loaderProgress: 0.0
	})

	// CALLBACKS
	//
		const init = useCallback(() =>
		{			
			store.settings.assetsById = keyBy(store.settings.assets,'id')
			
			setupLoader()
			setupDisplay()
			startLoader()
			addListeners()

		},[])

		const setupLoader = useCallback(() =>
		{
			const target = refs.current.pixiWrapper
			
			pixi.current.renderer = new PIXI.Renderer(
			{
				backgroundColor: 0xffffff,
				transparent: true,
				antialias: true,
				forceFXAA: true,
				autoDensity: true
			})

			pixi.current.renderer.resize(target.offsetWidth, target.offsetHeight);
			
			pixi.current.stage = new PIXI.Container();
	
			pixi.current.renderer.view.id = 'pixi-loader';
	
			target.appendChild(pixi.current.renderer.view);
	
			const loader = new preloader({ xhrImages: !true })

			loader.on('progress',progress => 
			{
				refs.current.loaderProgress = progress;
				updateDisplay();
			})

			loader.on('complete', () => 
			{
				refs.current.loaderProgress = 1;
				hideDisplay();
			})

			each(
				filter(store.settings.assets, { "preload":true }), 
				item =>
				{
					let url = process.env.REACT_APP_BASEPATH
					
					if(typeof item === 'object')
					{
						url += item.url
						
						if(item.id)
						{
							item.options = item.options || {}
							item.audioOptions = item.audioOptions || {}
							item.options.onComplete = e =>
							{
								if(item.type === 'audio')
								{
									store.loader.assets[item.id] = PixiSound.Sound.from({
										url: item.url,
										//source: e,
										singleInstance: true,
										preload: true,
										...item.audioOptions
									})
								}	
								else
								{
									
								}
									store.loader.assets[item.id] = {
										url: item.url,
										data: e
									}
							}
						}
						
						switch(item.type)
						{
							case 'json':
								loader.addJSON(url,{ ...item.options })
								break
								
							case 'video':
								loader.addVideo(url,{ ...item.options })
								break
								
							case 'audio':
								loader.addAudio(url,{ ...item.options })
								break
							
							default:
								loader.add(url,{ ...item.options })
						}
					}
					else
					{
						url += item
						
						loader.add(url)
					}	
				}
			)

			refs.current.loader = loader

		},[])

		const updateTweenLoad = useCallback(() =>
		{
			updateDisplay();
		},[])

		const tweenLoadComplete = useCallback(() =>
		{
			hideDisplay();
		},[])

		const updateDisplay = useCallback(() =>
		{
			if(refs.current.tweenLoadProgress < refs.current.loaderProgress)
			{
				pixi.current.filters["hexagonal"].percent = 1.0 - refs.current.tweenLoadProgress
				pixi.current.filters["badTV"].percent = 1.0 - refs.current.tweenLoadProgress;
				pixi.current.filters["jitter"].percent = 1.0 - refs.current.tweenLoadProgress;
			}
			else
			{
				pixi.current.filters["hexagonal"].percent = 1.0 - refs.current.loaderProgress;
				pixi.current.filters["badTV"].percent = 1.0 - refs.current.loaderProgress;
				pixi.current.filters["jitter"].percent = 1.0 - refs.current.loaderProgress;
			}

		},[])

		const hideDisplay = useCallback(() =>
		{
			updateDisplay();

			if(!props.debug && refs.current.loaderProgress === 1 && refs.current.tweenLoadProgress  === 1)
			{
				if(!store.assetsLoaded)
					store.update('assetsLoaded',true)

				TweenLite.to(['#loader'], 0.75, { alpha:0, delay:1.1, ease: Expo.easeOut, onComplete:hideDisplayComplete })
			}

		},[])

		const hideDisplayComplete = useCallback(() =>
		{
			removeListeners();
			stopLoader();

		},[])

		const startLoader = useCallback(() =>
		{			
			TweenLite.fromTo(refs.current, refs.current.minLoadTime, { tweenLoadProgress:0.0},{ tweenLoadProgress:1.0, ease: Linear.easeOut, onComplete:tweenLoadComplete, onUpdate:updateTweenLoad, onUpdateParams:[refs.current]});
			
			refs.current.loader.load()

		},[])

		const stopLoader = useCallback(() =>
		{
			pixi.current.stage.filters = null;

			// Destroy stage and children
			pixi.current.stage.destroy({children:true, texture:true, baseTexture:true});

			//Destroy renderer and view
			pixi.current.renderer.destroy(true);

			if(!store.siteRevealed)
				store.update('siteRevealed',true)
				
		},[])

		const setupDisplay = useCallback(() =>
		{	
			refs.current.vw = pixi.current.renderer.width
			refs.current.vh = pixi.current.renderer.height
			refs.current.cx = pixi.current.renderer.width / 2
			refs.current.cy = pixi.current.renderer.height / 2

			if(isMobileOnly)
			{
				if(isIOS)
				{
					if(isSafari)
					{
						if(window.Modernizr.ios13)
						{
							//y = refs.current.cy - h/2 - 35;
						}
						else
						{
							//y = refs.current.cy - h/2 - 20;
						}
					}
					else if(isFacebookBrowser)
					{
						//y = refs.current.cy - h/2 + 20;
					}
					else if(isChrome)
					{

					}
					else if(isFirefox)
					{

					}
				}
				else if(isAndroid)
				{
					if(isFacebookBrowser)
					{

					}
					else if(isChrome)
					{

					}
					else if(isFirefox)
					{
						
					}
				}
			}

			const svgMarkup = `<svg 
				xmlns="http://www.w3.org/2000/svg" 
				xmlns:xlink="http://www.w3.org/1999/xlink" 
				version="1.1" 
				viewBox="0 0 80 133.7" 
				width="160"
				height="267.4"
				xml:space="preserve">
				<path fill="red" d="M72 73L33.7.3 21.8 21.7l10 18.4 18.9 36 4.2 7.7c2.7 4.1 3.7 9.2 2.3 14.2-2.6 9.4-12.5 14.8-22.1 12.2-9.6-2.6-15.3-12.4-12.7-21.8l.3-.9c.6-2.2 9.3-18 14-26.5l12.6 20.2-23.1-43.5v.2l-.2-.6C16.1 55.5 4.4 77.6 3.5 81.5h.1c-.2.7-.4 1.3-.6 2-5.6 20.3 6.3 41.4 26.7 47 20.3 5.6 41.4-6.3 47-26.7 3-10.8.9-21.9-4.7-30.8"/>
				</svg>`

			pixi.current.graphics["background"] = new PIXI.Graphics()
			.beginFill(0x000000,1.0)
			.drawRect(0, 0, refs.current.vw, refs.current.vh)
			.endFill();

			pixi.current.stage.addChild(pixi.current.graphics["background"]);
			
			pixi.current.sprites["logo"] = new PIXI.Sprite.from(svgMarkup);
			pixi.current.sprites["logo"].anchor.set(0.5);
			pixi.current.sprites["logo"].x = refs.current.cx;
			pixi.current.sprites["logo"].y = refs.current.cy;
			pixi.current.stage.addChild(pixi.current.sprites["logo"]);

			pixi.current.filters["hexagonal"] = new HexagonalFilter();
			pixi.current.filters["badTV"] = new BadTVFilter();
			pixi.current.filters["jitter"] = new JitterFilter();
			//pixi.current.filters["kawaseBlur"] = new KawaseBlurFilter();
			//pixi.current.filters["ascii"] = new AsciiFilter();
			pixi.current.filters["crt"] = new CRTFilter();
			pixi.current.stage.filters = [pixi.current.filters["hexagonal"].filter,pixi.current.filters["badTV"].filter,pixi.current.filters["jitter"].filter,pixi.current.filters["crt"].filter];

		},[])

		const renderLoop = useCallback(() =>
		{
			refs.current.time.previous = refs.current.time.current;
			refs.current.time.current = TweenLite.ticker.time;
			refs.current.time.delta = refs.current.time.current - refs.current.time.previous;

			pixi.current.filters["badTV"].time = refs.current.time.current + 0.1 * refs.current.time.delta;
			pixi.current.filters["crt"].time = refs.current.time.delta * 2000;

			pixi.current.filters["hexagonal"].update();
			pixi.current.filters["badTV"].update();
			pixi.current.filters["jitter"].update();
			pixi.current.filters["crt"].update();

			pixi.current.renderer.render(pixi.current.stage)

		},[])
		
		const onResize = useCallback(({ width,height }={}) =>
		{
			const target = refs.current.pixiWrapper;

			pixi.current.renderer.resize(target.offsetWidth, target.offsetHeight);

			pixi.current.filters["hexagonal"].ratio = pixi.current.stage.width/pixi.current.stage.height;

			refs.current.vw = pixi.current.renderer.width
			refs.current.vh = pixi.current.renderer.height
			refs.current.cx = pixi.current.renderer.width / 2
			refs.current.cy = pixi.current.renderer.height / 2

			pixi.current.graphics["background"]
			.beginFill(0x000000,1.0)
			.drawRect(0, 0, refs.current.vw, refs.current.vh)
			.endFill();

			pixi.current.sprites["logo"].x = refs.current.cx;
			pixi.current.sprites["logo"].y = refs.current.cy;

		},[])

		const addListeners = useCallback(() =>
		{			
			// It is necessary to add the listener with: "this, false, 1" because if not renderLoop gets called after
			// the listener is removed.  The "1" sets the listener with a positive priority (meaning its called earlier)
			// It appears that normally the GSAP ticker always updates AFTER the core engine does its updates. (Which would explain why it gets called even after removeEventlistener is called) 
			// see: https://greensock.com/forums/topic/16174-tweenliteticker-does-not-work-as-expected/
			TweenLite.ticker.addEventListener('tick', renderLoop, this, false, 1);
			store.eventDispatcher.on('resize',onResize)
			//window.addEventListener('mousemove',onMouseMove)
			onResize()

		},[onResize])
		
		const removeListeners = useCallback(() =>
		{			
			TweenLite.ticker.removeEventListener('tick', renderLoop);
			store.eventDispatcher.off('resize',onResize)
			//window.removeEventListener('mousemove',onMouseMove)

		},[onResize])

		const onMouseMove = useCallback((e) =>
		{
			let centeredX = e.clientX - (window.innerWidth/2)
			let centeredXNormalized = centeredX / (window.innerWidth/2);
			let xNormalized = e.clientX / window.innerWidth;

			refs.current.mouseXNormalized = xNormalized;

			//pixi.current.filters["blur"].uniforms.progress = refs.current.mouseXNormalized;

			/*pixi.current.filters["hexagonal"].percent = refs.current.mouseXNormalized;
			pixi.current.filters["hexagonal"].ratio = pixi.current.stage.width/pixi.current.stage.height;
			pixi.current.filters["hexagonal"].update();

			pixi.current.filters["badTV"].percent = refs.current.mouseXNormalized;
			pixi.current.filters["badTV"].update();

			pixi.current.filters["jitter"].percent = refs.current.mouseXNormalized;
			pixi.current.filters["jitter"].update();*/

			/*pixi.current.filters["kawaseBlur"].percent = refs.current.mouseXNormalized;
			pixi.current.filters["kawaseBlur"].update();

			pixi.current.filters["crt"].percent = refs.current.mouseXNormalized;
			pixi.current.filters["crt"].update();*/

		},[])
	
	// EFFECTS
	//
		useEffect(() =>
		{
			
			init()
			
		},[])

	return (
		<div 
			id='loader'
			ref={e => { if(e) refs.current.pixiWrapper = e }}
		></div>
	)
}

export default Loader