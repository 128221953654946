import React, { useState,useEffect,useRef } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import axios from 'axios'
import { map } from 'lodash-es'
import { StoreContext } from 'components/Data/StoreContext'

import Layout from 'components/Layout'
import Analytics from 'components/Analytics/Analytics'
import EventDispatcher from 'components/EventDispatcher/EventDispatcher'
import { addScript } from 'components/Utils/Utils'

import 'styles/main.scss'

if(typeof window === 'undefined')
{
	// SSR stuff in here...
}

const App = props =>
{
	const [_state,setState] = useState(
	{
		settingsReady: false,
		assetsLoaded: false,
		siteRevealed: false
	})

	const isFetching = useRef(false)

	const data = useRef(
	{
		settings: {},
		
		debug: false,
		analytics: null,
		pageId: null,
		dataReady: false,
		settingsReady: false,
		assetsLoaded: false,
		siteRevealed: false,
		history: [],
		eventDispatcher: EventDispatcher,
		pages: [],
		backgrounds:{},
		scrollTarget: null,
		scrollListeners: [],
		
		loader:
		{
			assets:			{}
		},

		pixi:
		{
			debug:			true,
			
			app:			null,
			assets:			{},
			resources:		{},
			textures:		{},
			sprites:		{},
			graphics:		{},
			containers:		{},
			filters:		{},
			misc:			{},
			videos: 		{},
			lastRender:		0,
		},
		mouse:
		{
			x: 0,
			y: 0
		},
		
		update: (k,v) =>
		{
			data.current[k] = v

			if(k === 'settingsReady' || k === 'assetsLoaded' || k === 'siteRevealed')
			{
				let newState = { ..._state }
				newState[k] = v

				setState(newState)
			}
		}
	})
	
	useEffect(() =>
	{
		if(isFetching.current || _state.settingsReady)
			return	
				
		const addGDPR = () =>
		{
			const head = document.querySelector('head')

			addScript({
				src: '',
				attributes:
				{
					id: 'gdpr',
					type: 'text/javascript',
					charset: 'UTF-8',
					'data-src': 'https://cdn.cookielaw.org/consent/bdfe0f4c-aa21-423e-933c-41f0a7c0d39b.js'
				},
				target: head,
				placement: 'prepend'
			})
		}
		
		const fetchSettings = async () =>
		{
			isFetching.current = true
			
			const request = await axios.get(`${process.env.PUBLIC_URL}/settings.json`)
			const json = request.data

			data.current.settings = json
			if(json && typeof json.debug !== 'undefined')
				data.current.debug = json.debug

			let scriptTag
			
			data.current.analytics = new Analytics({
				type: json.analytics.type,
				id: json.analytics.id,
				options:
				{ 
					debug: json.analytics.debug, 
					disabled: !json.analytics.enabled 
				},
				events:
				{
					ready: () =>
					{
						scriptTag = document.querySelector('head script[src^="https://www.googletagmanager.com/gtm.js"]')
						if(!scriptTag)
							scriptTag = document.querySelector('head script[data-src^="https://www.googletagmanager.com/gtm.js"]')
						
						if(scriptTag)
						{
							scriptTag.setAttribute('type','text/plain')
							scriptTag.setAttribute('class','optanon-category-4')
						}

						// activate script for dev...
						//
						// if(window.location.href.indexOf('localhost') !== -1)
						// {
						// 	scriptTag.setAttribute('src',scriptTag.getAttribute('data-src'))
						// }

						addGDPR()
					},
					error: () =>
					{
						scriptTag = document.querySelector('head script[src^="https://www.googletagmanager.com/gtm.js"]')
						if(!scriptTag)
							scriptTag = document.querySelector('head script[data-src^="https://www.googletagmanager.com/gtm.js"]')
						
						if(scriptTag)
						{
							scriptTag.setAttribute('type','text/plain')
							scriptTag.setAttribute('class','optanon-category-4')
						}

						// activate script for dev...
						//
						// if(window.location.href.indexOf('localhost') !== -1)
						// {
						// 	scriptTag.setAttribute('src',scriptTag.getAttribute('data-src'))
						// }

						addGDPR()
					}
				}
			})

			data.current.settingsReady = true
			data.current.update('settingsReady',true)
		}

		try 
		{
			fetchSettings()
		}
		catch(exception)
		{
			console.log({ exception })
		}

	},[_state])

	return (
		<Router basename={`${process.env.REACT_APP_BASEPATH}`}>
			<StoreContext.Provider value={data.current}>
				{/* loading ? '<p style="color:yellow">Loading...</p>' : null */}
				{/* error ? `<p style='color:red'>Error! ${error.message}</p>` : null */}
				{ data.current.settingsReady ? <Layout /> : null }
			</StoreContext.Provider>
		</Router>
	)
}

export default App