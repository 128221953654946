import React,{ useEffect } from 'react'
import { isMobileOnly } from 'react-device-detect'
import useOrientation from '../CustomHooks/useOrientation'
import { getAssetUrl } from '../Utils/Utils'

import './Roadblock.scss'

const Roadblock = props =>
{
	const { isLandscape } = useOrientation()
	const visible = isMobileOnly && isLandscape

	// init stuff
	//
	useEffect(() =>
	{
		
	},[])
	
	
	return (
		<div 
			id='roadblock'
			className={`${visible ? 'active' : ''}`}
		>
			<div className='lockup'>
				<img className='logo' src={getAssetUrl('/assets/images/logo.svg')} alt='' />
				<p>This site is best viewed in portrait mode. Please rotate your device to continue.</p>
			</div>
		</div>
	)
}

export default Roadblock