import React, { Fragment, useContext } from 'react'
import { BrowserRouter as Router,Route,Switch,withRouter } from 'react-router-dom'
//import { Spring,Transition,Keyframes,animated,config } from 'react-spring/renderprops'
import { CSSTransition,TransitionGroup } from 'react-transition-group'
import { isMobileOnly } from 'react-device-detect'
import useOrientation from './CustomHooks/useOrientation'

//import { StoreContext } from './Data/StoreContext'

//import PageShell from './UI/PageShell'
//import _Loading from './UI/Loading'
//import ErrorBoundary from './UI/ErrorBoundary'
//import { ReactComponent as Spinner } from '../assets/images/puff.svg'

import Home from '../pages/Home/Home'
import NotFound from '../pages/NotFound/NotFound'

//const Loading = _Loading()

/*
const Home = lazy(() => {
return Promise.all([
	import("./pages/Home"),
	new Promise(resolve => setTimeout(resolve, 2500))
])
.then(([moduleExports]) => moduleExports);
})

const About = lazy(() => {
return Promise.all([
	import("./pages/About"),
	new Promise(resolve => setTimeout(resolve, 2500))
])
.then(([moduleExports]) => moduleExports);
})

const NotFound = lazy(() => {
return Promise.all([ 	
	import("./pages/NotFound"),
	new Promise(resolve => setTimeout(resolve, 2500))
])
.then(([moduleExports]) => moduleExports);
})
*/


// <ErrorBoundary>
// 	<Suspense fallback={<Loading><Spinner /></Loading>}>
// 	</Suspense>
// </ErrorBoundary>


const Main = ({ location,dataReady,...props }) =>
{
	const { isLandscape } = useOrientation()
	const visible = isMobileOnly && isLandscape

	return (
		<main
			 id='main'
			 className={`${visible ? 'roadblock' : ''}`}
		>
			<Route
				render={({ location }) => (
					<Fragment>
						<TransitionGroup className='page-shell'>
							<CSSTransition
								key={location.pathname}
								classNames="transition"
								timeout={1450}
							>
								<Switch location={location}>
									<Route exact path="/" render={props => <Home dataReady={dataReady} {...props} />} />
									<Route render={props => <NotFound dataReady={dataReady} {...props} />} />
								</Switch>
							</CSSTransition>
						</TransitionGroup>
					</Fragment>
				)}
			/>
		</main>
	)
}

export default withRouter(Main)