//import React, { useRef,useContext,useCallback } from 'react'
import { each,bindAll,keys,size } from 'lodash-es'
import { TweenLite,TimelineLite,Expo } from 'gsap/all'
import * as PIXI from 'pixi.js'
import {CRTFilter} from '@pixi/filter-crt';

// eslint-disable-next-line
const treeShakeBlocker = []

export default class CRT
{	
	constructor()
	{
		this.percent = 0.0;
		this.time = 0.0;
		this.vignettingBlur = 0.3;
		this.vignettingAlpha = 1.0;
		this.vignetting = 0.3;
		this.seed = 0;
		this.noiseSize = 1.0;
		this.noise = 0.3;
		this.verticalLine = false;
		this.lineContrast = 0.25;
		this.lineWidth = 1.0;
		this.curvature = 1.0;

		this.filter = new CRTFilter();
		this.filter.autoFit = true;
		this.update();
	}

	update()
	{
		this.filter.time = this.time;
		this.filter.vignettingBlur = this.vignettingBlur;
		this.filter.vignettingAlpha = this.vignettingAlpha;
		this.filter.vignetting = this.vignetting;
		this.filter.seed = this.seed;
		this.filter.noiseSize = this.noiseSize;
		this.filter.noise = this.noise;
		this.filter.verticalLine = this.verticalLine;
		this.filter.lineContrast = this.lineContrast;
		this.filter.lineWidth = this.lineWidth;
		this.filter.curvature = this.curvature;
	}
}