/* global */
import React, { Fragment,useEffect,useContext,useRef } from 'react'
import Helmet from 'react-helmet'
import { filter,map } from 'lodash-es'
import { StoreContext } from 'components/Data/StoreContext'
import Logo from 'components/UI/Logo'
import ProjectGrid from 'components/ProjectGrid/ProjectGrid'

import './Home.scss'

// eslint-disable-next-line
const treeShakeBlocker = []

const Home = props =>
{
	const store = useContext(StoreContext)
	const data = useRef(null)
	const cssBaseSelector = '.page[data-page-id="404"]'

	useEffect(() => 
	{
		store.analytics.pageView('/')
		store.addScrollTracking(`${cssBaseSelector}`)

	},[])

	useEffect(() =>
	{
		if(store.pages.length)
		{
			data.current = filter(store.pages, { slug: store.pageId })[0]
		}
		
	},[store.pages])
	
	return (
		<Fragment>
			<Helmet>
				<title>{store.settings.metaTags.home.pageTitle}</title>
				<meta name='title' content={store.settings.metaTags.home.metaTitle} />
				<meta name='description' content={store.settings.metaTags.home.metaDescription} />
				<link rel='canonical' href={`${process.env.REACT_APP_SITE_URL}/`} />
			</Helmet>
			<div 
				data-page-id='home' 
				className='page'
				style={props.styles}
			>
				<div className='content'>
					<div className='logo-wrapper'>
						<Logo />
					</div>
					<div className='blurb-wrapper'>
						<div className='blurb'>
							{ map(store.settings.home, (blurb,i) => { return <p key={`home-p${i}`}>{blurb}</p> }) }
						</div>

						<button className='cta' onClick={() => { window.open('mailto:hello@droplab.com','_blank')}}>Get In Touch</button>
					</div>
				</div>

				<div className='project-grid-wrapper'>
					<ProjectGrid 
						
					/>
				</div>
			</div>
		</Fragment>
	)
}

export default Home